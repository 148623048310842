import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { showModifierInfo } from 'Cashout/helpers/dialogs';

const HorceRacingModifiers = ({ modifiers: { DH, FPTP, R4 } = {} }) => (
  <>
    {[
      DH && 'dead_heat',
      FPTP && 'first_past_the_post',
      !!R4 && 'rule4',
    ].filter(Boolean).map((modifier) => (
      <div
        className="co-card__hr-text"
        key={modifier}
        onClick={() => showModifierInfo(modifier)}
      >
        {t(`javascript.cashout_horse_race.${modifier}_button_label`)}
      </div>
    ))}
  </>
);

HorceRacingModifiers.propTypes = {
  modifiers: PropTypes.shape({
    DH: PropTypes.bool,
    FPTP: PropTypes.bool,
    R4: PropTypes.number,
  }),
};

HorceRacingModifiers.defaultProps = {
  modifiers: {},
};

export default HorceRacingModifiers;
