import { locale } from 'bv-i18n';

const MARKET_STATUS_SUSPENDED = 2;

export const bestOddsGuaranteed = (values) => values?.modifiers?.BOG;

export const legResult = (values) => {
  switch (values?.result?.result1) {
    case 'CASHED_OUT':
      return 'cashed-out';
    case 'PUSH':
      return ['WON', 'LOST'].includes(values?.result?.result2)
        ? `push-${values.result.result2.toLowerCase()}`
        : 'push';
    case 'LOST':
    case 'NONE':
    case 'PLACED':
    case 'SUSPENDED':
    case 'VOID':
    case 'WON':
      return values?.result?.result1.toLowerCase();
    default:
      return '';
  }
};

export const hasResult = (values) => !!values?.result;

export const isNone = (values) => values?.result?.result1 === 'NONE';

export const isWon = (values) => values?.result?.result1 === 'WON';

export const isLost = (values) => values?.result?.result1 === 'LOST';

export const isVoid = (values) => values?.result?.result1 === 'VOID';

export const isSuspended = ({ market: { statusId } }) => statusId === MARKET_STATUS_SUSPENDED;

export const isOpenLeg = (values) => values?.or === 0 || isNone(values);

export const canNavigateToEvent = (leg) => {
  const { inPlay, scoreboard, values } = leg;
  return inPlay ? scoreboard?.cal : isOpenLeg(values);
};

export const eventUrl = ({ event, isGreyhound, meetingId }) => {
  const meetingPath = isGreyhound ? '' : `/meetings/${meetingId}`;
  return `/sports/${event.pathId}${meetingPath}/events/${event.id}`;
};

export const startDate = (date) => new Date(date).toLocaleDateString(locale(), {
  day: 'numeric',
  month: 'short',
});

export const startTime = (date) => new Date(date).toLocaleTimeString(locale(), {
  hour: '2-digit',
  minute: '2-digit',
});

export const formatFullDate = (date) => new Date(date).toLocaleString(
  locale(),
  {
    day: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  },
);
