import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';
import HelpCenterLink from './help_center_link';

const CashoutInfoModal = ({ onClose }) => (
  <RenderInBody>
    <Modal
      title={t('javascript.cashout.info.what_is_cashout')}
      className="co-info-modal-wrapper"
      icon={false}
      iconClosable
      onCloseClick={onClose}
    >
      <div className="co-info-modal">
        <p>{t('javascript.cashout.info.main_text_1')}</p>
        <p>{t('javascript.cashout.info.main_text_2')}</p>
        <p>{t('javascript.cashout.info.terms')}</p>

        <HelpCenterLink onClick={onClose} />
      </div>
    </Modal>
  </RenderInBody>
);

CashoutInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CashoutInfoModal;
