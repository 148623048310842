import PropTypes from 'prop-types';
import { BetSource } from 'SharedComponents/my_bets/constants';
import factory from './factory';

const ReAddToBetslipButton = ({ betSource, ...props }) => {
  const Component = factory(betSource);

  return <Component betSource={betSource} {...props} />;
};

ReAddToBetslipButton.propTypes = {
  betSource: PropTypes.oneOf(Object.values(BetSource)),
};

ReAddToBetslipButton.defaultProps = {
  betSource: null,
};

export default ReAddToBetslipButton;
