import PropTypes from 'prop-types';
import { underscored } from 'underscore.string';
import { SmallSpinner } from 'bv-components';
import { t } from 'bv-i18n';

const ReAddToBetslipButton = ({ betSource, loading, onClick }) => (
  <div className="co-card__add-to-betslip">
    {
      loading ? <SmallSpinner /> : (
        <div className="bvs-link" onClick={onClick}>
          {t(`javascript.cashout.add_to${betSource ? `_${underscored(betSource)}` : ''}_betslip`)}
        </div>
      )
    }
  </div>
);

ReAddToBetslipButton.propTypes = {
  betSource: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ReAddToBetslipButton.defaultProps = {
  betSource: null,
};

export default ReAddToBetslipButton;
