import PropTypes from 'prop-types';

const SuspensionMessageView = ({ suspensions }) => suspensions.map(({ description }) => (
  <p key={description}>
    {description}
  </p>
));

SuspensionMessageView.propTypes = {
  suspensions: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
  })).isRequired,
};

export default SuspensionMessageView;
