import classnames from 'classnames';
import { v as bvVar } from 'bv';
import { Reversible } from 'bv-components';
import PropTypes from 'prop-types';

const EventDescription = ({
  leg: {
    event: {
      description,
      opponentADesc,
      opponentBDesc,
    },
    isGreyhound,
    isHorseRacing,
    meetingDescription,
  },
  americanFormat,
  hasScoreboardHeaders,
}) => (
  <div className={classnames('co-event__outcomes', {
    'co-event__outcomes--with-headers': hasScoreboardHeaders,
  })}
  >
    {(opponentADesc && opponentBDesc) ? (
      <Reversible reverse={americanFormat && bvVar('useAmericanFormat')}>
        <p>{opponentADesc}</p>
        <p>{opponentBDesc}</p>
      </Reversible>
    ) : (
      <p>
        {(isHorseRacing || isGreyhound) && `${meetingDescription} `}
        {!isGreyhound && description}
      </p>
    )}
  </div>
);

EventDescription.propTypes = {
  leg: PropTypes.shape({
    event: PropTypes.shape({
      description: PropTypes.string,
      opponentADesc: PropTypes.string,
      opponentBDesc: PropTypes.string,
    }),
    isGreyhound: PropTypes.bool.isRequired,
    isHorseRacing: PropTypes.bool.isRequired,
    meetingDescription: PropTypes.string,
  }).isRequired,
  hasScoreboardHeaders: PropTypes.bool.isRequired,
  americanFormat: PropTypes.bool.isRequired,
};

export default EventDescription;
