import { throttle } from 'underscore';

// offset so that we start loading more bets before reaching the end of the bet list
const OFFSET = 15;

export const loadMoreBetsOnScroll = (betListRef, scrollableRef, loadMoreBets) => () => {
  const list = betListRef.current;

  const onScroll = throttle(() => {
    if (window.innerHeight > list.offsetHeight + list.getBoundingClientRect().top - OFFSET) {
      loadMoreBets();
    }
  }, 300);

  scrollableRef.addEventListener('scroll', onScroll);

  return () => { scrollableRef.removeEventListener('scroll', onScroll); };
};
