import { t } from 'bv-i18n';
import ModalBodyMessages from 'Cashout/components/modal_body_messages';

const { ModalHelper } = window;

const showModal = ({ title, messages }) => {
  ModalHelper.showInfoMessage({
    title,
    children: <ModalBodyMessages messages={messages} />,
    icon: true,
    actions: [{ label: t('javascript.close'), close: true, primary: true }],
  });
};

export const showModifierInfo = (modifier) => {
  showModal({
    title: t(`javascript.cashout_horse_race.${modifier}_title`),
    messages: [1, 2, 3, 4, 5].map((n) => `javascript.cashout_horse_race.${modifier}_modal.line_${n}`),
  });
};

export const showPartialCashoutInfo = () => {
  showModal({
    title: t('javascript.cashout.partial_cashout.modal.title'),
    messages: [
      'javascript.cashout.partial_cashout.modal.line_1',
      'javascript.cashout.partial_cashout.modal.line_2',
    ],
  });
};
