import { offersPartialCashout } from './bets';
import { proportionPartialCashout } from './partial_cashout';

export const updateBets = (bets, newBets) => bets.map((bet) => {
  const {
    cashoutValue, partialCashoutValue, id,
  } = bet;
  const newBet = newBets[id];
  return newBet ? {
    ...bet,
    ...newBet,
    partialCashoutValue: offersPartialCashout(bet) && partialCashoutValue < cashoutValue
      ? proportionPartialCashout(partialCashoutValue, cashoutValue, newBet.cashoutValue)
      : newBet.cashoutValue,
  } : bet;
});
