import PropTypes from 'prop-types';
import { exist, t } from 'bv-i18n';

const ModalBodyMessages = ({ messages }) => messages.filter(exist).map((key) => (
  <p key={key}>{t(key)}</p>
));

ModalBodyMessages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ModalBodyMessages;
