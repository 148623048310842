import { useState, useEffect } from 'react';
import { DAY_IN_MILLIS } from 'bv-helpers/datetime';
import { useFeature } from 'bv-hooks';
import { get, set } from 'bv-local-storage';
import { fetchLastCashoutDate } from 'Cashout/api';
import CashoutInfoView from './cashout_info_view';

const dismissedLocalStorageKey = 'cashout_info_dismissed';

const MILLISECONDS_180_DAYS = 180 * DAY_IN_MILLIS;

const CashoutInfoContainer = () => {
  const [dismissed, setDismissed] = useState(get(dismissedLocalStorageKey));
  const [cashedOutLast180Days, setCashedOutLast180Days] = useState(true);
  const [cashoutEnabled] = useFeature('cashout');

  const dismiss = () => {
    set(dismissedLocalStorageKey, true);
    setDismissed(true);
  };

  useEffect(() => {
    if (cashoutEnabled && !dismissed) {
      fetchLastCashoutDate().then((lastCashoutDate) => setCashedOutLast180Days(
        (new Date() - new Date(lastCashoutDate)) < MILLISECONDS_180_DAYS,
      ));
    }
  }, [cashoutEnabled]);

  return cashoutEnabled && !dismissed && !cashedOutLast180Days
    && <CashoutInfoView onDismissClick={dismiss} />;
};

export default CashoutInfoContainer;
