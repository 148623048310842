import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';
import { formatAmount } from 'Cashout/helpers/bets';

const BetReturnsInfo = ({
  formattedTotalPrice,
  hasPartialCashoutCalc,
  isSettledBet,
  loading,
  normalReturns,
  returns,
  promoCash,
  taxDeductPct,
  totalCost,
}) => {
  const formattedReturns = normalReturns ? formatAmount(normalReturns) : '-';
  const isSP = formattedTotalPrice === 'SP';

  return (
    <div className="co-returns">
      <div className="co-returns__column">
        {t(isSettledBet
          ? 'javascript.cashout.settled.total_stake'
          : `javascript.${hasPartialCashoutCalc ? 'remaining_stake' : 'stake'}`)}
        <div className="co-returns__amounts">
          {promoCash && <Icon id="bonus-cash" alternative className="co-returns__icon" />}
          {loading ? <Icon id="cashout-returns-loading" /> : <span>{formatAmount(totalCost) || '-'}</span>}
        </div>
      </div>
      <div className="co-returns__column">
        {t(isSettledBet ? 'javascript.cashout.settled.total_returns' : 'javascript.potential_returns')}

        <div className="co-returns__amounts">
          {loading
            ? <Icon id="cashout-returns-loading" />
            : (
              <span
                className={`${normalReturns && returns > normalReturns ? 'co-returns__strike-text' : ''}`}
              >
                {isSP ? formattedTotalPrice : formattedReturns}
              </span>
            )}
          {!loading && returns > normalReturns && <span>{formatAmount(returns)}</span>}
          {!loading && taxDeductPct
            && <span className="co-returns__tax">{t('javascript.cashout.tax_deduction', { percentage: taxDeductPct })}</span>}
        </div>
      </div>
    </div>
  );
};

BetReturnsInfo.propTypes = {
  formattedTotalPrice: PropTypes.string.isRequired,
  hasPartialCashoutCalc: PropTypes.bool.isRequired,
  isSettledBet: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  normalReturns: PropTypes.number,
  promoCash: PropTypes.bool.isRequired,
  returns: PropTypes.number,
  taxDeductPct: PropTypes.string,
  totalCost: PropTypes.number,
};

BetReturnsInfo.defaultProps = {
  loading: false,
  normalReturns: null,
  returns: null,
  taxDeductPct: null,
  totalCost: null,
};

export default BetReturnsInfo;
