import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { addMiddleware } from 'redux-dynamic-middlewares';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, isEqual } from 'underscore';
import Bets from 'Cashout/components/bets_redux';
import Nav from 'Cashout/components/nav';
import MyBetsContext from 'Cashout/context';
import { updateSubscriptions } from 'Cashout/helpers/contexts';
import cashOutReducer, { clearBets, removeBet } from 'Cashout/state/duck';
import middleware from 'Cashout/state/middleware';
import { selectContexts } from 'Cashout/state/selectors';
import { fetchOpenBet, fetchSettledBet, updateOpenBet } from 'Cashout/state/thunks';
import PanicNotification from 'PanicButton';
import { BetAction, BetUpdateType } from 'SharedComponents/my_bets/constants';
import { subscribeToBets } from 'SharedComponents/my_bets/subscribers';

const { addReducers } = window.reduxState;

addReducers({ cashout: cashOutReducer });
addMiddleware(middleware);

const App = ({ eventId, scrollableRef }) => {
  const [betsStatus, setBetsStatus] = useState('open');
  const contexts = useSelector(selectContexts, isEqual);
  const rightSidebar = useSelector((state) => state.rightSidebar);
  const dispatch = useDispatch();
  const betListRef = useRef(null);
  const isEventLevel = !!eventId;
  const parsedEventId = isEventLevel ? parseInt(eventId, 10) : null;

  const loadSettledBet = compose(dispatch, fetchSettledBet);

  const contextValue = useMemo(
    () => ({
      eventId: parsedEventId,
      isEventLevel,
      betsStatus: isEventLevel ? 'event' : betsStatus,
      betListRef,
      scrollableRef: isEventLevel ? window : scrollableRef.current,
    }),
    [betListRef, betsStatus, eventId, isEventLevel, scrollableRef],
  );

  const removeOpenBet = (betId) => {
    dispatch(removeBet('open', betId));
    dispatch(removeBet('event', betId));
  };

  useEffect(() => subscribeToBets(({
    betAction, betId, betUpdateType, eventIds,
  }) => {
    switch (betUpdateType) {
      case BetUpdateType.NEW: {
        dispatch(fetchOpenBet({
          betId,
          eventId: parsedEventId,
          eventIds,
          isEventLevel,
        }));
        break;
      }
      case BetUpdateType.SETTLED: {
        setTimeout(() => removeOpenBet(betId), 4000);
        if (!isEventLevel) {
          loadSettledBet({ betId });
        }
        break;
      }
      case BetUpdateType.UPDATED: {
        const isPartialCashout = BetAction.PARTIAL_CASHOUT === betAction;
        const timeout = isPartialCashout ? 4000 : 0;
        setTimeout(() => dispatch(updateOpenBet({
          betId,
          eventId: parsedEventId,
          eventIds,
          isEventLevel,
          reset: isPartialCashout,
        })), timeout);
        break;
      }
      default: break;
    }
  }), []);

  useEffect(() => () => {
    if (eventId) {
      dispatch(clearBets('event'));
    }
  }, []);

  useEffect(() => {
    updateSubscriptions(contexts);
  }, [contexts]);

  useEffect(() => {
    setBetsStatus('open');
    if (!isEventLevel && contextValue.scrollableRef) {
      contextValue.scrollableRef.scrollTop = 0;
    }
  }, [rightSidebar?.visible, rightSidebar?.component]);

  return (
    <MyBetsContext.Provider value={contextValue}>
      <div className="cashout-wrapper" ref={betListRef}>
        {
          isEventLevel ? <Bets /> : (
            <>
              <div className="cashout-top-panic-button__wrapper">
                <PanicNotification source="cashout-top" />
              </div>
              <Nav selected={betsStatus} onClick={setBetsStatus} />
              <Bets />
              <div className="cashout-bottom-panic-button__wrapper">
                <PanicNotification source="cashout-bottom" />
              </div>
            </>
          )
        }
      </div>
    </MyBetsContext.Provider>
  );
};

App.propTypes = {
  eventId: PropTypes.number,
  scrollableRef: PropTypes.instanceOf(Object).isRequired,
};

App.defaultProps = {
  eventId: null,
};

export default App;
