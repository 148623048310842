import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { addMultiple } from 'bv-services/betslip-adapter';
import { showBetslip } from 'right-sidebar';
import { isOpenLeg } from 'Cashout/helpers/legs';
import ReAddToBetslipButton from './re_add_to_betslip_button';

const ReAddToSportsbookBetslip = ({ legs }) => {
  const [isAddingToBetslip, setIsAddingToBetslip] = useState(false);

  const outcomesIds = useMemo(() => legs
    .filter(({ values }) => isOpenLeg(values))
    .map((({ outcome }) => outcome.id)), [legs]);

  const handleOnClick = async () => {
    setIsAddingToBetslip(true);
    addMultiple(outcomesIds)
      .then(showBetslip)
      .finally(() => setIsAddingToBetslip(false));
  };

  return outcomesIds.length > 0 && (
    <ReAddToBetslipButton
      onClick={handleOnClick}
      loading={isAddingToBetslip}
    />
  );
};

ReAddToSportsbookBetslip.propTypes = {
  legs: PropTypes.arrayOf(Object).isRequired,
};

export default ReAddToSportsbookBetslip;
