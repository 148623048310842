import classnames from 'classnames';
import PropTypes from 'prop-types';
import { showClock } from 'SportsbookCommon/overview/clock_info/helpers';
import Clock from './clock';
import Period from './period';
import StartTime from './start_time';

const ClockInfo = ({
  leg: {
    event: {
      startTime,
    },
    inPlay,
    sportId,
  },
  hasScoreboardHeaders,
  scoreboard,
}) => {
  const renderClock = () => showClock(scoreboard.clk, sportId)
    && <Clock clk={scoreboard.clk} />;

  return (
    <div className={classnames('co-event__clock', {
      'co-event__clock--with-headers': hasScoreboardHeaders,
    })}
    >
      {inPlay && scoreboard ? (
        <>
          <p>{renderClock()}</p>
          <Period dsc={scoreboard.dsc} gDsc={scoreboard.gDsc} />
        </>
      ) : <StartTime time={startTime} />}
    </div>
  );
};

ClockInfo.propTypes = {
  leg: PropTypes.shape({
    inPlay: PropTypes.bool.isRequired,
    event: PropTypes.shape({
      startTime: PropTypes.string,
    }),
    sportId: PropTypes.number.isRequired,
  }).isRequired,
  hasScoreboardHeaders: PropTypes.bool.isRequired,
  scoreboard: PropTypes.shape({
    clk: PropTypes.string.isRequired,
    dsc: PropTypes.string.isRequired,
    gDsc: PropTypes.string,
  }),
};

ClockInfo.defaultProps = {
  scoreboard: null,
};

export default ClockInfo;
