export const buildBet = (bet) => ({
  ...bet,
  partialCashoutValue: bet.cashoutValue,
  cashedOut: false,
  confirmingCashout: false,
  rejectedCashout: false,
  cashingOut: false,
  partialCashoutCalc: null,
});

export const getSuspendedLegs = (bet) => {
  const outcomeIds = bet.suspensions.map((suspension) => suspension.oId).filter(Boolean);
  return bet.legs.filter((leg) => outcomeIds.includes(leg.outcome.id));
};

export const getBetIdsByContext = (bets, context) => bets
  .filter(({ contexts }) => contexts.includes(context))
  .map(({ id }) => id);

export const getContexts = ({ bets }) => bets.flatMap(({ contexts }) => contexts);

export const getNewBets = (state, bets) => {
  const betsIds = state.bets.map(({ id }) => id);
  return bets.filter(({ id }) => !betsIds.includes(id));
};

export const getScoreboards = (bets) => bets
  .flatMap((bet) => bet.legs.map((leg) => leg.scoreboard).filter(Boolean));
