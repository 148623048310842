import classNames from 'classnames';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const BankMyStake = ({
  onClick, position, suspended,
}) => (
  <div className={classNames('co-bank-my-stake', {
    'co-bank-my-stake--suspended': suspended,
  })}
  >
    <div
      className="co-bank-my-stake__mark"
      style={{ left: `${position}%` }}
    >
      <span className="co-bank-my-stake__label" onClick={onClick}>
        {t('javascript.cashout.bank_my_stake')}
      </span>
    </div>
  </div>
);

BankMyStake.propTypes = {
  onClick: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  suspended: PropTypes.bool.isRequired,
};

export default BankMyStake;
