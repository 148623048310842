import { useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { throttle } from 'underscore';
import { Icon } from 'bv-components';
import { showPartialCashoutInfo } from 'Cashout/helpers/dialogs';
import { getRange } from 'Cashout/helpers/partial_cashout';

const Slider = ({
  betId,
  cashoutValue,
  partialCashoutValue,
  onChange,
  showBankMyStake,
  bankMyStakePos,
  suspended,
}) => {
  const { step, min } = getRange(partialCashoutValue);
  const rangeRef = useRef(null);
  const throttleOnChange = useCallback(throttle(onChange, 10), []);

  const updateSlider = (event, rangeEl) => {
    const target = rangeEl || event.target;
    target.style.setProperty('--value', target.value);
    target.style.setProperty('--min', target.min === '' ? min : target.min);
    target.style.setProperty('--max', target.max === '' ? cashoutValue : target.max);
    target.style.setProperty('--value', target.value);
    throttleOnChange(event ? parseFloat(event.target.value) : partialCashoutValue);
  };

  useEffect(() => {
    updateSlider(null, rangeRef.current);
  }, [partialCashoutValue]);

  return (
    <div className={classNames('co-slider__options', {
      'co-slider__options--suspended': suspended,
    })}
    >
      <Icon
        id="information"
        onClick={showPartialCashoutInfo}
        className="bvs-link co-slider__info-icon"
      />
      <div className="co-slider-wrapper">
        <input
          ref={rangeRef}
          type="range"
          className={classNames('co-slider', `bms${betId}`, {
            'is-bank-my-stake': showBankMyStake,
          })}
          max={cashoutValue}
          min={min}
          step={step}
          onChange={updateSlider}
          value={partialCashoutValue}
        />
        {showBankMyStake && (
          <style>
            {`.co-slider.is-bank-my-stake.bms${betId}::after {
              left: ${bankMyStakePos}%;
            }`}
          </style>
        )}
      </div>
    </div>
  );
};

Slider.propTypes = {
  betId: PropTypes.string.isRequired,
  cashoutValue: PropTypes.number.isRequired,
  partialCashoutValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  showBankMyStake: PropTypes.bool.isRequired,
  bankMyStakePos: PropTypes.number.isRequired,
  suspended: PropTypes.bool.isRequired,
};

export default Slider;
