import PropTypes from 'prop-types';
import factory from './factory';

const Scoreboard = ({ scoreboard }) => {
  const { goesInPlay, matchStarted } = scoreboard;
  const Component = factory(scoreboard);

  return goesInPlay && matchStarted && Component && (
    <div className="co-event__score">
      <Component scoreboard={scoreboard} />
    </div>
  );
};

Scoreboard.propTypes = {
  scoreboard: PropTypes.shape({
    goesInPlay: PropTypes.bool.isRequired,
    matchStarted: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Scoreboard;
