import { getJSON, postJSON } from 'bv-fetch';
import { accountNumber } from 'bv-helpers/session';
import { getErrorMessage } from 'Cashout/helpers/get_error_message';
import { basePath } from 'SharedComponents/my_bets/api';

export const fetchBets = ({
  status, eventId, page, betsIds,
}) => getJSON(
  `${basePath}/${status}/bets?_=${accountNumber()}`,
  { page, event_id: eventId, bets_ids: betsIds },
);

export const fetchBet = (status, id) => getJSON(`${basePath}/${status}/bets/${id}`);

const timeoutUntilTimestamp = (delay) => {
  if (delay <= 0) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    window.setTimeout(resolve, delay);
  });
};

const retryRequest = async (payload, { requestId, delay }) => {
  await timeoutUntilTimestamp(delay);
  const newPayload = {
    ...payload,
    first_request: false,
    request_id: requestId,
  };
  try {
    const { appliedCashoutValue } = await postJSON(`${basePath}/cashouts`, newPayload, { 'X-Requested-With': 'XMLHttpRequest' });
    return { done: true, appliedCashoutValue };
  } catch ({ body }) {
    const { cashoutValue, code, failureCode } = await body;
    let attachedPayload = null;
    if (code === 1000) {
      attachedPayload = cashoutValue;
    }
    const generatedError = getErrorMessage(code, failureCode);
    return { done: false, error: generatedError, attachedPayload };
  }
};

export const requestCashOut = async (payload) => {
  let status;
  let attachedPayload;

  try {
    const { appliedCashoutValue } = await postJSON(`${basePath}/cashouts`, {
      ...payload,
      first_request: true,
      request_id: false,
    }, { 'X-Requested-With': 'XMLHttpRequest' });

    status = { done: true, appliedCashoutValue };
  } catch ({ body }) {
    const errorResponse = await body;
    const { code, failureCode } = errorResponse;

    if (code) {
      if (code === 3000) {
        return retryRequest(payload, errorResponse);
      }
      if (code === 1000) attachedPayload = errorResponse.cashoutValue;
      const generatedError = getErrorMessage(code, failureCode);
      status = { done: false, error: generatedError, attachedPayload };
    }
  }
  return status;
};

export const fetchCashoutCalc = (payload) => postJSON(`${basePath}/cashouts/calc`, payload);

export const fetchLastCashoutDate = () => getJSON('/api/betting_stats/last_cash_out')
  .catch(() => null);
