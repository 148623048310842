import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import classnames from 'classnames';

const ResultRibbon = ({ result, pendingPayout }) => (
  <div className={classnames('co-card__ribbon', {
    [`is-${result}`]: result && !pendingPayout,
    [`is-${result}-pending`]: result && pendingPayout,
  })}
  >
    {t(`javascript.cashout.settled.result.${result.replace(/-/g, '_')}`)}
    {pendingPayout && ` - ${t('javascript.cashout.settled.pending_payout.title')}`}
  </div>
);

ResultRibbon.propTypes = {
  result: PropTypes.string.isRequired,
  pendingPayout: PropTypes.bool.isRequired,
};

export default ResultRibbon;
