import PropTypes from 'prop-types';
import { startDate, startTime } from 'Cashout/helpers/legs';

const StartTime = ({ time }) => (time ? (
  <>
    <p>{startDate(time)}</p>
    <p>{startTime(time)}</p>
  </>
) : null);

StartTime.propTypes = {
  time: PropTypes.string.isRequired,
};

export default StartTime;
