import { t } from 'bv-i18n';

const Waiting = () => (
  <div className="co-message co-message--cashing-out">
    <div className="cashing-out">
      <span className="cashing-out-note" />
    </div>

    <p>{t('javascript.cashout.cashing_out')}</p>
  </div>
);

export default Waiting;
