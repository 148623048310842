import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { formatAmount, formatPcoDate } from 'Cashout/helpers/bets';

const PartialCashouts = ({ partialCashOuts }) => partialCashOuts.length > 0 && (
  <div className="co-history">
    {partialCashOuts.map(({ cashOutDate, credit, id }) => (
      <p key={id}>
        <span>{formatPcoDate(cashOutDate)}</span>
        <span>
          {t('javascript.cashout.partial_cashout.history.credit')}
          <span>
            {formatAmount(credit) }
          </span>
        </span>
      </p>
    ))}
  </div>
);

PartialCashouts.propTypes = {
  partialCashOuts: PropTypes.arrayOf(PropTypes.shape({
    cashOutDate: PropTypes.string.isRequired,
    credit: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
};

export default PartialCashouts;
