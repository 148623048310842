import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import MyBetsContext from 'Cashout/context';
import { loadMoreBetsOnScroll } from 'Cashout/helpers/load_more_bets_on_scroll';
import BetsView from './bets_view';

const BetsContainer = ({
  betsIds, loadBets, loadMoreBets, loading, nextPage,
}) => {
  const {
    betListRef, betsStatus, isEventLevel, scrollableRef,
  } = useContext(MyBetsContext);

  useEffect(() => {
    if (nextPage === 1) {
      loadBets();
    }
  }, [betsStatus]);

  useEffect(loadMoreBetsOnScroll(betListRef, scrollableRef, loadMoreBets), [betsStatus]);

  return (
    <BetsView
      betsIds={betsIds}
      betsStatus={betsStatus}
      isEventLevel={isEventLevel}
      loading={loading}
      nextPage={nextPage}
    />
  );
};

BetsContainer.propTypes = {
  betsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  loadBets: PropTypes.func.isRequired,
  loadMoreBets: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  nextPage: PropTypes.number.isRequired,
};

export default BetsContainer;
