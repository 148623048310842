import PropTypes from 'prop-types';

const ExtraWinnings = ({ className, text, title }) => (
  <div className={className}>
    <p className="co-message__title">
      {title}
    </p>
    <p className="co-message__text">{text}</p>
  </div>
);

ExtraWinnings.propTypes = {
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ExtraWinnings;
