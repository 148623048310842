import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MyBetsContext from 'Cashout/context';
import { makeSelectSuspensions } from 'Cashout/state/selectors';
import SuspendedIconView from './suspended_icon_view';

const SuspendedIconRedux = ({ betId }) => {
  const { betsStatus } = useContext(MyBetsContext);
  const selectSuspensions = useMemo(makeSelectSuspensions, []);
  const suspensions = useSelector((state) => selectSuspensions(state, {
    betsStatus,
    id: betId,
  }));

  return <SuspendedIconView suspensions={suspensions} />;
};

SuspendedIconRedux.propTypes = {
  betId: PropTypes.string.isRequired,
};

export default SuspendedIconRedux;
