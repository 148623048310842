/**
 * A bet has associated contexts, those contexts are push endpoints associated to the different
 * things that can make the cashout value / cashout status of a Bet to change.
 * For instance a Bet could have the following contexts:
 *
 * cashout/outcome/1000 - If the outcome with id 1000 changes the cashout value / status of the
 *                        bet could change
 * cashout/event/3000 - If the event with id 1000 changes the cashout value / status of the bet
 *                      could change
 * cashout/market/2000 - If the market with id 1000 changes the cashout value / status of the
 *                      bet could change
 * Each time we receive a notification to one of a context endpoint we should update the cashout
 * value / status of all the bets that have that context associated, we will do that with a
 * request through pgate (in order to avoid rails stack for that fetch)
 */

import { omit } from 'underscore';
import { subscribeToContexts } from 'Cashout/api/subscribers';
import { fetchNewCashoutValues } from 'Cashout/state/thunks';

const { store } = window.reduxState;

const onContextUpdate = (context) => store.dispatch(fetchNewCashoutValues(context));

// holds a map of subscriptions to unsubscribe functions, for example:
// { 'cashout/outcome/1000': { unsubscribe: unsubscribeFn } }
let subscriptions = {};

export const updateSubscriptions = (contexts) => {
  const subscribedContexts = Object.keys(subscriptions);
  const contextsToSubscribe = contexts.filter(
    (context) => !subscribedContexts.includes(context));
  const contextsToUnsubscribe = subscribedContexts.filter(
    (context) => !contexts.includes(context));

  contextsToUnsubscribe.forEach((context) => subscriptions[context].unsubscribe());

  subscriptions = {
    ...omit(subscriptions, contextsToUnsubscribe),
    ...subscribeToContexts(contextsToSubscribe, onContextUpdate),
  };
};
