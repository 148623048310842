import { t } from 'bv-i18n';

const getSuspensionCode = (suspensions) => suspensions[0]?.c;

const getCardinality = (suspensions) => (suspensions.length > 1 ? 'multiple' : 'single');

const hasSpecificTranslationSuffix = (sportIds) => {
  const specificSports = [200];

  return sportIds.length === 1 && specificSports.includes(sportIds[0]);
};

const getSuffix = (sportIds) => (hasSpecificTranslationSuffix(sportIds) ? sportIds[0] : 'default');

const getSportIds = (legs) => Array.from(new Set(legs.map((leg) => leg.sportId)));

const translationParameters = (legs) => {
  const firstLeg = legs[0];

  if (legs.length === 1) {
    if (firstLeg.sportId === 200) {
      return {
        leg: firstLeg.outcome.description,
        event: firstLeg.event.description,
        meeting: firstLeg.meetingDescription,
      };
    }

    return {
      event: firstLeg.meetingDescription,
    };
  }

  return {
    descriptions: legs.map((leg) => {
      if (leg.sportId === 200) {
        return `${leg.outcome.description} ${leg.event.description} ${leg.meetingDescription}`;
      }
      return leg.meetingDescription;
    }).join(', '),
  };
};

export const getInfo = (suspensions, suspendedLegs) => {
  const suspensionCode = getSuspensionCode(suspensions);
  const cardinality = getCardinality(suspensions);
  const suffix = getSuffix(getSportIds(suspendedLegs));

  return {
    type: 'info',
    description: t(
      `javascript.cashout.suspensions.${suspensionCode}.${cardinality}.${suffix}`,
      translationParameters(suspendedLegs),
    ),
  };
};
