import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { formattedAmount } from 'Cashout/helpers';
import ExtraWinnings from './extra_winnings';

const BogExtraMoney = ({ amount }) => (
  <ExtraWinnings
    className="co-message co-message--success-bog"
    title={t('javascript.cashout.settled.bog_extra_money.title')}
    text={t('javascript.cashout.settled.bog_extra_money.text', { amount: formattedAmount(amount) })}
  />
);

BogExtraMoney.propTypes = {
  amount: PropTypes.number.isRequired,
};

export default BogExtraMoney;
