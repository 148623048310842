import { registerHandler, unregisterHandler } from 'event-bus';

export const subscribeToContexts = (contexts, callback) => contexts
  .reduce((subscriptions, context) => {
    const update = () => {
      callback(context);
    };
    registerHandler(context, update);

    return {
      ...subscriptions,
      [context]: {
        unsubscribe: () => unregisterHandler(context, update),
      },
    };
  }, {});
