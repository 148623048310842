import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { Overview } from 'sportsbook-common';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';

const EventCommentRedux = ({
  sportId,
  event: {
    id: eventId,
    opponentADesc: oppADesc,
    opponentBDesc: oppBDesc,
    opponentAId: oppAId,
  },
}) => {
  const { selectors: { makeGetEventMostRecentComment } } = EssentialScoresRedux;
  const getEventMostRecentComment = useMemo(makeGetEventMostRecentComment, []);
  const comment = useSelector((state) => getEventMostRecentComment(state, { eventId }), isEqual);

  return (
    <Overview.EventCommentContainer
      event={{
        sportId, oppAId, oppADesc, oppBDesc,
      }}
      comment={comment}
    />
  );
};

EventCommentRedux.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    opponentADesc: PropTypes.string,
    opponentBDesc: PropTypes.string,
    opponentAId: PropTypes.string,
  }).isRequired,
  sportId: PropTypes.number.isRequired,
};

export default EventCommentRedux;
