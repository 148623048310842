import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose, debounce } from 'underscore';
import { requestCashOut } from 'Cashout/api';
import MyBetsContext from 'Cashout/context';
import { offersPartialCashout } from 'Cashout/helpers/bets';
import {
  cashoutBetSuccess,
  setCashingOut,
  setRejectedCashout,
  updatePartialCashoutValue,
  updateSingleCashoutValue,
} from 'Cashout/state/duck';
import { makeSelectBet } from 'Cashout/state/selectors';
import { fetchPcoCalc as fetchPcoCalcThunk } from 'Cashout/state/thunks';
import Accepted from './accepted';
import Offered from './offered';
import Rejected from './rejected';
import Waiting from './waiting';

const Summary = ({ betId }) => {
  const { betsStatus } = useContext(MyBetsContext);
  const selectBet = useMemo(makeSelectBet, []);
  const bet = useSelector((state) => selectBet(state, { betsStatus, id: betId }));
  const dispatch = useDispatch();
  const fetchPcoCalc = compose(dispatch, fetchPcoCalcThunk);
  const [rejectedMessage, setRejectedMessage] = useState('');

  const {
    cashingOut,
    cashedOut,
    cashoutStatus,
    cashoutValue,
    confirmingCashout,
    partialCashOuts,
    partialCashoutValue,
    rejectedCashout,
    totalCost,
  } = bet;

  const setPartialCashoutValue = (value) => dispatch(
    updatePartialCashoutValue(betId, betsStatus, value));

  const handleBankStakeClick = () => setPartialCashoutValue(totalCost);

  const handleCashoutClick = async () => {
    const cashoutPayload = {
      bet_id: betId,
      cashout_value: cashoutValue,
      partial_cashout_value: (partialCashoutValue < cashoutValue
        ? partialCashoutValue : undefined),
    };

    dispatch(setCashingOut(betId, betsStatus, true));

    const {
      done, error, attachedPayload, appliedCashoutValue,
    } = await requestCashOut(cashoutPayload);

    if (done) {
      dispatch(cashoutBetSuccess(betId, betsStatus, appliedCashoutValue
        ? parseFloat(appliedCashoutValue) : partialCashoutValue));
    } else {
      if (attachedPayload) {
        dispatch(
          updateSingleCashoutValue(betId, betsStatus, parseFloat(attachedPayload.cashoutValue)));
      }
      setRejectedMessage(error);
      dispatch(setRejectedCashout(betId, betsStatus, true));
    }
  };

  const debounceFetchPcoCalc = useCallback(debounce(fetchPcoCalc, 500), []);

  useEffect(() => {
    debounceFetchPcoCalc(bet, partialCashoutValue, betsStatus);
  }, [partialCashoutValue]);

  if (rejectedCashout) {
    return (
      <Rejected
        errorMessage={rejectedMessage}
        onReset={() => dispatch(setRejectedCashout(betId, betsStatus, false))}
      />
    );
  }

  if (cashingOut) {
    return <Waiting />;
  }

  if (cashedOut) {
    return <Accepted amount={partialCashoutValue} />;
  }

  return (
    <Offered
      betId={betId}
      canBeCashout={cashoutStatus}
      offersPartialCashout={offersPartialCashout(bet)}
      cashoutValue={cashoutValue}
      confirmingCashout={confirmingCashout}
      partialCashoutValue={partialCashoutValue}
      partialCashOuts={partialCashOuts}
      totalCost={totalCost}
      onBankStakeClick={handleBankStakeClick}
      onCashout={handleCashoutClick}
      onPartialCashOutValueChange={setPartialCashoutValue}
    />
  );
};

Summary.propTypes = {
  betId: PropTypes.string.isRequired,
};

export default Summary;
