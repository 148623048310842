import { updateBets } from 'Cashout/helpers/cashout_values';
import { buildBet, getNewBets } from './helpers';

export const FETCH_BETS_INIT = 'cashout/FETCH_BETS_INIT';
export const FETCH_BETS_SUCCESS = 'cashout/FETCH_BETS_SUCCESS';
export const ADD_BET = 'cashout/ADD_BET';
export const REMOVE_BET = 'cashout/REMOVE_BET';
export const UPDATE_BET = 'cashout/UPDATE_BET';
export const UPDATE_CASHOUT_VALUES = 'cashout/UPDATE_CASHOUT_VALUES';
export const UPDATE_SINGLE_CASHOUT_VALUE = 'cashout/UPDATE_SINGLE_CASHOUT_VALUE';
export const UPDATE_PARTIAL_CASHOUT_VALUE = 'cashout/UPDATE_PARTIAL_CASHOUT_VALUE';
export const FETCH_PARTIAL_CASHOUT_CALC_INIT = 'cashout/FETCH_PARTIAL_CASHOUT_CALC_INIT';
export const FETCH_PARTIAL_CASHOUT_CALC_SUCCESS = 'cashout/FETCH_PARTIAL_CASHOUT_CALC_SUCCESS';
export const CLEAR_PARTIAL_CASHOUT_CALC = 'cashout/CLEAR_PARTIAL_CASHOUT_CALC';
export const CASHOUT_BET_SUCCESS = 'cashout/CASHOUT_BET_SUCCESS';
export const SET_CONFIRMING_CASHOUT = 'cashout/SET_CONFIRMING_CASHOUT';
export const SET_REJECTED_CASHOUT = 'cashout/SET_REJECTED_CASHOUT';
export const SET_CASHING_OUT = 'cashout/SET_CASHING_OUT';
export const CLEAR_BETS = 'cashout/CLEAR_BETS';
export const FETCH_SO_FAR_SCORES_SUCCESS = 'cashout/FETCH_SO_FAR_SCORES_SUCCESS';

const defaultSectionState = {
  bets: [],
  loading: true,
  nextPage: 1,
};

const defaultState = {
  event: { ...defaultSectionState },
  open: { ...defaultSectionState },
  settled: { ...defaultSectionState },
  soFarScores: {},
};

export const fetchBetsInit = (section) => ({
  type: FETCH_BETS_INIT,
  payload: { section },
});

export const fetchBetsSuccess = (section, data) => ({
  type: FETCH_BETS_SUCCESS,
  payload: { section, data },
});

export const addBet = (section, bet) => ({
  type: ADD_BET,
  payload: { bet, section },
});

export const removeBet = (section, betId) => ({
  type: REMOVE_BET,
  payload: { betId, section },
});

export const updateBet = ({
  section, betId, data, reset,
}) => ({
  type: UPDATE_BET,
  payload: {
    section, betId, data, reset,
  },
});

export const updateCashoutValues = (values) => ({
  type: UPDATE_CASHOUT_VALUES,
  payload: { values },
});

export const updateSingleCashoutValue = (betId, section, cashoutValue) => ({
  type: UPDATE_SINGLE_CASHOUT_VALUE,
  payload: { betId, section, cashoutValue },
});

export const updatePartialCashoutValue = (betId, section, partialCashoutValue) => ({
  type: UPDATE_PARTIAL_CASHOUT_VALUE,
  payload: { betId, section, partialCashoutValue },
});

export const fetchPartialCashoutCalcInit = (betId, section) => ({
  type: FETCH_PARTIAL_CASHOUT_CALC_INIT,
  payload: { betId, section },
});

export const fetchPartialCashoutCalcSuccess = (betId, section, data) => ({
  type: FETCH_PARTIAL_CASHOUT_CALC_SUCCESS,
  payload: { betId, section, data },
});

export const removePartialCashoutCalc = (betId, section) => ({
  type: CLEAR_PARTIAL_CASHOUT_CALC,
  payload: { betId, section },
});

export const cashoutBetSuccess = (betId, section, appliedCashoutValue) => ({
  type: CASHOUT_BET_SUCCESS,
  payload: { betId, section, appliedCashoutValue },
});

export const setConfirmingCashout = (betId, section, confirmingCashout) => ({
  type: SET_CONFIRMING_CASHOUT,
  payload: { betId, section, confirmingCashout },
});

export const setRejectedCashout = (betId, section, rejectedCashout) => ({
  type: SET_REJECTED_CASHOUT,
  payload: { betId, section, rejectedCashout },
});

export const setCashingOut = (betId, section, cashingOut) => ({
  type: SET_CASHING_OUT,
  payload: { betId, section, cashingOut },
});

export const clearBets = (section) => ({
  type: CLEAR_BETS,
  payload: { section },
});

export const fetchSoFarScoresSuccess = (payload) => ({
  type: FETCH_SO_FAR_SCORES_SUCCESS,
  payload,
});

const addBets = (state, bets) => ({
  ...state,
  bets: [...state.bets, ...getNewBets(state, bets).map(buildBet)],
});

const updateBetData = (state, section, betId, data, reset = false) => ({
  ...state,
  [section]: {
    ...state[section],
    bets: state[section].bets.map((bet) => ((bet.id === betId) ? {
      ...bet,
      ...(reset ? buildBet(data) : data),
    } : bet)),
  },
});

const updateSoFarScores = (state, scoreResult) => ({
  ...state,
  soFarScores: {
    ...state.soFarScores,
    [scoreResult.address]: scoreResult,
  },
});

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case FETCH_BETS_INIT: {
      const { section } = action.payload;

      return {
        ...state,
        [section]: {
          ...state[section],
          loading: true,
        },
      };
    }
    case FETCH_BETS_SUCCESS: {
      const { data: { bets, nextPage }, section } = action.payload;

      return {
        ...state,
        [section]: {
          ...addBets(state[section], bets),
          loading: false,
          nextPage,
        },
      };
    }
    case ADD_BET: {
      const { bet, section } = action.payload;

      return {
        ...state,
        [section]: addBets(state[section], [bet]),
      };
    }
    case REMOVE_BET: {
      const { betId, section } = action.payload;

      return {
        ...state,
        [section]: {
          ...state[section],
          bets: state[section].bets.filter((bet) => bet.id !== betId),
        },
      };
    }
    case UPDATE_CASHOUT_VALUES: {
      const { values } = action.payload;
      const { event, open } = state;

      return {
        ...state,
        open: {
          ...open,
          bets: updateBets(open.bets, values),
        },
        event: {
          ...event,
          bets: updateBets(event.bets, values),
        },
      };
    }
    case UPDATE_SINGLE_CASHOUT_VALUE: {
      const { betId, section, cashoutValue } = action.payload;
      return updateBetData(state, section, betId, {
        cashoutValue,
        partialCashoutValue: cashoutValue,
      });
    }
    case UPDATE_PARTIAL_CASHOUT_VALUE: {
      const { betId, section, partialCashoutValue } = action.payload;
      return updateBetData(state, section, betId, { partialCashoutValue });
    }
    case FETCH_PARTIAL_CASHOUT_CALC_INIT: {
      const { betId, section } = action.payload;
      return updateBetData(state, section, betId, {
        partialCashoutCalc: {
          loading: true,
        },
      });
    }
    case FETCH_PARTIAL_CASHOUT_CALC_SUCCESS: {
      const { betId, section, data } = action.payload;

      return updateBetData(state, section, betId, {
        partialCashoutCalc: {
          loading: false,
          ...data,
        },
      });
    }
    case CLEAR_PARTIAL_CASHOUT_CALC: {
      const { betId, section } = action.payload;
      return updateBetData(state, section, betId, { partialCashoutCalc: null });
    }
    case CASHOUT_BET_SUCCESS: {
      const { appliedCashoutValue, betId, section } = action.payload;
      return updateBetData(state, section, betId, {
        cashingOut: false,
        cashedOut: true,
        partialCashoutValue: appliedCashoutValue,
      });
    }
    case SET_CONFIRMING_CASHOUT: {
      const { betId, section, confirmingCashout } = action.payload;
      return updateBetData(state, section, betId, { confirmingCashout });
    }
    case SET_REJECTED_CASHOUT: {
      const { betId, section, rejectedCashout } = action.payload;
      return updateBetData(state, section, betId, { cashingOut: false, rejectedCashout });
    }
    case SET_CASHING_OUT: {
      const { betId, section, cashingOut } = action.payload;
      return updateBetData(state, section, betId, { cashingOut });
    }
    case CLEAR_BETS: {
      const { section } = action.payload;
      return {
        ...state,
        [section]: { ...defaultSectionState },
      };
    }
    case UPDATE_BET: {
      const {
        betId, section, data, reset,
      } = action.payload;
      return updateBetData(state, section, betId, data, reset);
    }
    case FETCH_SO_FAR_SCORES_SUCCESS: {
      return updateSoFarScores(state, action.payload);
    }
    default:
      return state;
  }
}
