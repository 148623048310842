import PropTypes from 'prop-types';

const Period = ({ dsc, gDsc }) => (
  <div>
    <p>{dsc}</p>
    {gDsc && (<p>{gDsc}</p>)}
  </div>
);

Period.propTypes = {
  dsc: PropTypes.string.isRequired,
  gDsc: PropTypes.string,
};

Period.defaultProps = {
  gDsc: null,
};

export default Period;
