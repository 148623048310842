import { toCurrency } from 'bv-helpers/number';
import { locale } from 'bv-i18n';
import { legResult } from './legs';
import { isOffered } from './partial_cashout';

export const betResult = ({ result, isSingle, legs }) => {
  if (result === 'CASHED_OUT') {
    return 'cashed-out';
  }
  const processedResult = isSingle ? legResult(legs[0].values) : result?.toLowerCase();

  // result will be none when a multiple settled bet returns <= bet stake
  return processedResult === 'none' ? null : processedResult;
};

export const isInRunning = (bet) => bet.legs.some((leg) => leg.inPlay);

export const earliestLeg = (
  bet,
) => [...bet.legs].sort((a, b) => new Date(a.event.startTime) - new Date(b.event.startTime))[0];

export const offersCashout = (bet) => bet.cashoutStatus === 1;

export const offersPartialCashout = (bet) => isOffered(
  bet.cashoutValue,
  bet.returns,
);

export const formatAmount = (amount) => toCurrency(amount, { decimalPlaces: 2 });

export const formatPcoDate = (date) => new Date(date).toLocaleDateString(locale(), {
  hour: 'numeric',
  minute: 'numeric',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
});
