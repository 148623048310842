import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  isWon,
  isLost,
  isVoid,
} from 'Cashout/helpers/legs';
import { useSoFarScores, SoFarScores } from './so_far_scores';

const BetBuilderLeg = ({ soFarScoresEnabled, leg }) => {
  useSoFarScores({ url: leg.scoreAddress, shouldRegister: soFarScoresEnabled });

  return (
    <div className={classnames('co-card__bb-leg', {
      'co-card__bb-leg-icon is-won': isWon(leg),
      'co-card__bb-leg-icon is-lost': isLost(leg),
      'co-card__bb-leg-icon is-void': isVoid(leg),
    })}
    >
      <span>{leg.description}</span>
      <SoFarScores id={leg.scoreAddress} />
    </div>
  );
};

BetBuilderLeg.propTypes = {
  leg: PropTypes.shape({
    scoreAddress: PropTypes.string,
    description: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
  }).isRequired,
  soFarScoresEnabled: PropTypes.bool.isRequired,
};

const BetBuilderLegs = ({ soFarScoresEnabled, legs }) => (
  <div className="co-card__bb-legs">
    {legs.map((leg) => (
      <BetBuilderLeg key={leg.hash} leg={leg} soFarScoresEnabled={soFarScoresEnabled} />
    ))}
  </div>
);

BetBuilderLegs.propTypes = {
  legs: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  soFarScoresEnabled: PropTypes.bool.isRequired,
};

export default BetBuilderLegs;
