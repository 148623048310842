import { getInfo } from './get_info';

const suspensionCodes = {
  EVENT_OR_MARKET_SUSPENDED: 1,
  OFFER_TOO_SMALL: 2,
  LEG_LOST: 3,
  LEG_NOT_TRADED_LIVE: 4,
  LEG_NOT_CASHOUT_ENABLED: 5,
  NO_ACTIVE_LEGS: 6,
  LEG_HAS_SP_PRICE: 7,
  CO_DISABLED_FOR_ACCOUNT: 8,
  PROMO_FUNDS: 9,
};

const messageBuilderFor = (code, suspensions, legs) => {
  switch (code) {
    case suspensionCodes.EVENT_OR_MARKET_SUSPENDED:
    case suspensionCodes.LEG_NOT_TRADED_LIVE:
    case suspensionCodes.LEG_HAS_SP_PRICE:
    case suspensionCodes.PROMO_FUNDS:
      return getInfo(suspensions, legs);
    case suspensionCodes.OFFER_TOO_SMALL:
      return { type: 'offer_too_small' };
    default:
      return null;
  }
};

export const getSuspensionMessage = (suspensions, legs) => Object.values(suspensionCodes)
  .reduce((acc, code) => {
    if (!suspensions) return acc;

    const suspensionsForCode = suspensions.filter((suspension) => suspension.c === code);

    if (suspensionsForCode.length === 0) return acc;

    const suspensionMessage = messageBuilderFor(code, suspensionsForCode, legs);

    return suspensionMessage ? [...acc, suspensionMessage] : acc;
  }, []);
