import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const Rejected = ({ errorMessage, onReset }) => {
  useEffect(() => {
    const timeout = setTimeout(onReset, 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="co-message co-message--rejected">
      <p className="co-message__title">
        {t('javascript.cashout.cashout_unsuccesful')}
      </p>
      <p className="co-message__text">{errorMessage}</p>
    </div>
  );
};

Rejected.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default Rejected;
