import { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import MyBetsContext from 'Cashout/context';
import {
  makeSelectBetsIds,
  makeSelectLoading,
  makeSelectNextPage,
} from 'Cashout/state/selectors';
import { fetchBets, fetchMoreBets } from 'Cashout/state/thunks';
import BetsContainer from './bets_container';

const BetsRedux = () => {
  const { betsStatus, eventId } = useContext(MyBetsContext);
  const selectBetsIds = useMemo(makeSelectBetsIds, []);
  const betsIds = useSelector((state) => selectBetsIds(state, { betsStatus }), isEqual);
  const selectLoading = useMemo(makeSelectLoading, []);
  const loading = useSelector((state) => selectLoading(state, { betsStatus }));
  const selectNextPage = useMemo(makeSelectNextPage, []);
  const nextPage = useSelector((state) => selectNextPage(state, { betsStatus }));

  const dispatch = useDispatch();

  const loadBets = () => dispatch(fetchBets(betsStatus, eventId));
  const loadMoreBets = () => dispatch(fetchMoreBets(betsStatus, eventId));

  return (
    <BetsContainer
      betsIds={betsIds}
      loading={loading}
      nextPage={nextPage}
      loadBets={loadBets}
      loadMoreBets={loadMoreBets}
    />
  );
};

export default BetsRedux;
