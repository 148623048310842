import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { buildScoreboard, Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import LegView from './leg_view';

const LegRedux = (props) => {
  const { leg: { event: { id: eventId } } } = props;
  const getScoreboard = useMemo(EssentialScoresRedux.selectors.makeGetEventScoreboard, []);
  const scoreboard = useSelector((state) => getScoreboard(state, { eventId }), isEqual);

  return <LegView {...props} scoreboard={buildScoreboard(scoreboard)} />;
};

LegRedux.propTypes = {
  leg: PropTypes.shape({
    event: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default LegRedux;
