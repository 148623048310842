import PropTypes from 'prop-types';
import { ButtonCard } from 'bv-components';
import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';

const HelpCenterLink = ({ onClick }) => (
  <ButtonCard
    className="co-info-modal__button"
    href={bvVar('helpCentrePageUrl')}
    onClick={onClick}
  >
    {t('javascript.cashout.info.help_center')}
  </ButtonCard>
);

HelpCenterLink.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default HelpCenterLink;
