import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'underscore';
import { compareBets } from 'Cashout/helpers/compare_bets';
import { getSuspensionMessage } from 'Cashout/helpers/suspensions/get_suspension_message';
import { getContexts, getSuspendedLegs } from './helpers';

const createEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
);

export const domain = (state) => state.cashout;

const selectBetsStatus = (_, { betsStatus }) => betsStatus;

export const makeSelectSection = () => createSelector(
  [domain, selectBetsStatus],
  (cashout, status) => cashout[status],
);

export const makeSelectLoading = () => createSelector(
  makeSelectSection(),
  (subState) => subState.loading,
);

export const makeSelectNextPage = () => createSelector(
  makeSelectSection(),
  (subState) => subState.nextPage,
);

export const makeSelectBets = () => createSelector(
  makeSelectSection(),
  (subState) => subState.bets);

export const makeSelectBetsIds = () => createSelector(makeSelectBets(),
  (bets) => bets
    .sort(compareBets)
    .map(({ id }) => id));

export const selectContexts = createSelector(
  domain,
  (cashout) => [...new Set([...getContexts(cashout.event), ...getContexts(cashout.open)])]);

const selectBetId = (_, { id }) => id;

export const makeSelectBet = () => createEqualSelector(
  [makeSelectBets(), selectBetId],
  (bets, betId) => bets.find((bet) => bet.id === betId),
);

export const makeSelectSuspensions = () => createSelector(
  makeSelectBet(),
  (bet) => getSuspensionMessage(bet.suspensions, getSuspendedLegs(bet))
    .filter((message) => message.type === 'info'),
);

export const selectSoFarScores = createSelector(
  domain,
  (cashout) => cashout.soFarScores,
);
