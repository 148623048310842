import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useEventBus } from 'bv-hooks';
import { fetchSoFarScoresSuccess } from 'Cashout/state/duck';

const useSoFarScores = ({ url, shouldRegister }) => {
  const dispatch = useDispatch();

  const handler = useCallback((err, response) => {
    if (err) return;
    dispatch(fetchSoFarScoresSuccess(response.body));
  }, [url]);

  useEventBus(
    url,
    handler,
    [url],
    { shouldRegister: shouldRegister && url },
  );
};

export default useSoFarScores;
