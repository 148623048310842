import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MyBetsContext from 'Cashout/context';
import { makeSelectBet } from 'Cashout/state/selectors';
import BetView from './bet_view';

const BetRedux = ({ id }) => {
  const { betsStatus } = useContext(MyBetsContext);
  const selectBet = useMemo(makeSelectBet, []);
  const bet = useSelector((state) => selectBet(state, { betsStatus, id }));

  return <BetView {...bet} />;
};

BetRedux.propTypes = {
  id: PropTypes.string.isRequired,
};

export default BetRedux;
