import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import classNames from 'classnames';
import { Button } from 'bv-components';
import MyBetsContext from 'Cashout/context';
import { formattedAmount, isWithinRange } from 'Cashout/helpers';
import { bankMyStakePosition } from 'Cashout/helpers/partial_cashout';
import { setConfirmingCashout } from 'Cashout/state/duck';
import BankMyStake from './bank_my_stake';
import Slider from './slider';
import SuspendedIcon from './suspended_icon_redux';

const Offered = ({
  betId,
  canBeCashout,
  cashoutValue,
  confirmingCashout,
  partialCashOuts,
  offersPartialCashout,
  onBankStakeClick,
  onCashout,
  onPartialCashOutValueChange,
  partialCashoutValue,
  totalCost,
}) => {
  const { betsStatus } = useContext(MyBetsContext);
  const dispatch = useDispatch();
  const showBankMyStake = !partialCashOuts.length && isWithinRange(totalCost, cashoutValue);
  const bankMyStakePos = bankMyStakePosition(totalCost, cashoutValue);

  useEffect(() => {
    const timeout = confirmingCashout
      && setTimeout(() => dispatch(setConfirmingCashout(betId, betsStatus, false)), 2000);
    return () => {
      if (confirmingCashout) {
        clearTimeout(timeout);
        dispatch(setConfirmingCashout(betId, betsStatus, false));
      }
    };
  }, [confirmingCashout]);

  const buttonLabel = () => {
    if (confirmingCashout) return 'selected';
    if (canBeCashout) return 'offered';
    return 'suspended';
  };

  const suspended = !canBeCashout && !confirmingCashout;

  return (
    <>
      <div className={classNames('co-button-wrapper', {
        'co-button-wrapper--suspended': suspended,
      })}
      >
        {!canBeCashout && <SuspendedIcon betId={betId} />}
        <Button
          onClick={confirmingCashout
            ? onCashout : () => dispatch(setConfirmingCashout(betId, betsStatus, true))}
          className={`co-button ${buttonLabel()}`}
          primary={!confirmingCashout}
          secondary={confirmingCashout}
          label={t(`javascript.cashout.bet_messages.${buttonLabel()}`,
            { amount: formattedAmount(partialCashoutValue) })}
        />
      </div>
      {offersPartialCashout && (
        <>
          {showBankMyStake && (
            <BankMyStake
              onClick={onBankStakeClick}
              position={bankMyStakePos}
              suspended={suspended}
            />
          )}
          <Slider
            betId={betId}
            cashoutValue={cashoutValue}
            partialCashoutValue={partialCashoutValue}
            onChange={onPartialCashOutValueChange}
            showBankMyStake={showBankMyStake}
            bankMyStakePos={bankMyStakePos}
            suspended={suspended}
          />
        </>
      )}
    </>
  );
};

Offered.propTypes = {
  betId: PropTypes.string.isRequired,
  canBeCashout: PropTypes.number.isRequired,
  cashoutValue: PropTypes.number.isRequired,
  confirmingCashout: PropTypes.bool.isRequired,
  partialCashOuts: PropTypes.arrayOf(Object).isRequired,
  offersPartialCashout: PropTypes.bool.isRequired,
  onBankStakeClick: PropTypes.func.isRequired,
  onCashout: PropTypes.func.isRequired,
  onPartialCashOutValueChange: PropTypes.func.isRequired,
  partialCashoutValue: PropTypes.number.isRequired,
  totalCost: PropTypes.number.isRequired,
};

export default Offered;
