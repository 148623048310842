import { indexBy } from 'underscore';
import { fetchBet, fetchBets as apiFetchBets, fetchCashoutCalc } from 'Cashout/api';
import {
  addBet,
  fetchBetsInit,
  fetchBetsSuccess,
  fetchPartialCashoutCalcInit,
  fetchPartialCashoutCalcSuccess,
  removePartialCashoutCalc,
  updateBet,
  updateCashoutValues,
} from './duck';
import { getBetIdsByContext } from './helpers';
import { makeSelectBetsIds, makeSelectNextPage } from './selectors';

export const fetchBets = (status, eventId, page = 1) => (dispatch) => {
  dispatch(fetchBetsInit(status));

  return apiFetchBets({ status: status === 'event' ? 'open' : status, eventId, page })
    .then((response) => {
      dispatch(fetchBetsSuccess(status, response));
    });
};

const selectNextPage = makeSelectNextPage();
const selectBetsIds = makeSelectBetsIds();

// avoid duplicated requests when My Bets is open at event level and floating nav simultaneously
const conditionallyFetchOpenBet = ({
  betId, eventId, eventIds, isEventLevel, createAction,
}) => (dispatch, getState) => {
  const state = getState();
  const eventBetsLoaded = selectNextPage(state, { betsStatus: 'event' }) !== 1;
  const openBetsLoaded = selectNextPage(state, { betsStatus: 'open' }) !== 1;
  const shouldFetch = isEventLevel || !eventBetsLoaded;

  return shouldFetch && fetchBet('open', betId).then((bet) => {
    if (openBetsLoaded) {
      dispatch(createAction('open', bet));
    }
    if (eventIds.includes(eventId)) {
      dispatch(createAction('event', bet));
    }
  });
};

export const fetchOpenBet = (args) => conditionallyFetchOpenBet({
  ...args,
  createAction: (section, bet) => addBet(section, bet),
});

export const updateOpenBet = ({ reset, ...args }) => conditionallyFetchOpenBet({
  ...args,
  createAction: (section, bet) => updateBet({
    section,
    betId: bet.id,
    data: bet,
    reset,
  }),
});

/**
 * we receive a SETTLED bet message for each outcome that is resolved, even if the bet is already
 * settled, so we need to ensure we only fetch a settled bet if that bet is currently an open bet
 */
export const fetchSettledBet = ({ betId }) => (dispatch, getState) => {
  const state = getState();
  const openBetsIds = selectBetsIds(state, { betsStatus: 'open' });
  const settledBetsLoaded = selectNextPage(state, { betsStatus: 'settled' }) !== 1;

  return openBetsIds.includes(betId) && settledBetsLoaded
    && fetchBet('settled', betId).then((bet) => {
      dispatch(addBet('settled', bet));
    });
};

export const fetchMoreBets = (status, eventId) => (dispatch, getState) => {
  const { cashout: { [status]: { loading, nextPage } } } = getState();

  if (!loading && nextPage) dispatch(fetchBets(status, eventId, nextPage));
};

export const fetchNewCashoutValues = (context) => (dispatch, getState) => {
  const { cashout: { open, event } } = getState();
  const betsIds = getBetIdsByContext([...open.bets, ...event.bets], context);

  if (betsIds.length) {
    apiFetchBets({ status: 'open', betsIds }).then(({ bets }) => {
      dispatch(updateCashoutValues(indexBy(bets, 'id')));
    });
  }
};

let currentPartialCashoutValue;
export const fetchPcoCalc = ({
  cashoutValue,
  normalReturns,
  returns,
  totalCost,
  id,
  unitStake,
}, partialCashoutValue, betStatus) => (dispatch) => {
  if (cashoutValue > partialCashoutValue) {
    dispatch(fetchPartialCashoutCalcInit(id, betStatus));
    currentPartialCashoutValue = partialCashoutValue;

    return fetchCashoutCalc({
      unit_stake: unitStake,
      bet_total_cost: totalCost,
      potential_win: normalReturns,
      gross_enhanced_potential_win: returns,
      co_value: cashoutValue,
      co_partial_value: partialCashoutValue,
    })
      .then((response) => {
        // We need this due to not having requests cancelation
        if (currentPartialCashoutValue !== partialCashoutValue) return;

        dispatch(fetchPartialCashoutCalcSuccess(id, betStatus, response));
      });
  }
  return dispatch(removePartialCashoutCalc(id, betStatus));
};
