import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';

const items = ['open', 'settled'];

const Nav = ({ onClick, selected }) => (
  <div className="co-nav">
    {items.map((item) => (
      <Button
        key={item}
        onClick={() => onClick(item)}
        noClass
        className="bvs-button-tab"
        active={item === selected}
      >
        {t(`javascript.cashout.nav.${item}`)}
      </Button>
    ))}
  </div>
);

Nav.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.oneOf(items).isRequired,
};

export default Nav;
