import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { formattedAmount } from 'Cashout/helpers';
import ExtraWinnings from './extra_winnings';

const CashoutCredit = ({ amount }) => (
  <ExtraWinnings
    className="co-message co-message--success"
    title={t('javascript.cashout.settled.cashout_credit')}
    text={formattedAmount(amount)}
  />
);

CashoutCredit.propTypes = {
  amount: PropTypes.number.isRequired,
};

export default CashoutCredit;
