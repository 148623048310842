import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'bv-components';
import { t } from 'bv-i18n';
import CashoutInfoModal from './cashout_info_modal';

const CashoutInfoView = ({ onDismissClick }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="co-info">
        <div className="bvs-card co-info__card">
          <p>{t('javascript.cashout.info.card.small_text')}</p>

          <div className="co-info__card-content">
            <p>{t('javascript.cashout.info.card.main_text')}</p>
            <Button
              inverse
              secondary
              onClick={() => setShowModal(true)}
            >
              {t('javascript.cashout.info.card.learn_more')}
            </Button>
          </div>
        </div>
        <div className="co-info__dismiss" onClick={onDismissClick}>
          <Icon id="close" />
          <p>{t('javascript.cashout.info.card.dismiss')}</p>
        </div>
      </div>
      {showModal && <CashoutInfoModal onClose={() => setShowModal(false)} />}
    </>
  );
};

CashoutInfoView.propTypes = {
  onDismissClick: PropTypes.func.isRequired,
};

export default CashoutInfoView;
