import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import {
  ADD_BET,
  FETCH_BETS_SUCCESS,
} from './duck';
import { getNewBets, getScoreboards } from './helpers';

const addScoreboards = ({ dispatch, getState }, section, bets) => {
  const newBets = getNewBets(getState().cashout[section], bets);
  dispatch(
    EssentialScoresRedux
      .actionCreators
      .upsertEssentialScores(getScoreboards(newBets)),
  );
};

export default (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_BETS_SUCCESS: {
      const { data: { bets }, section } = action.payload;

      addScoreboards(store, section, bets);
      break;
    }
    case ADD_BET: {
      const { bet, section } = action.payload;

      addScoreboards(store, section, [bet]);
      break;
    }
    default:
      break;
  }

  next(action);
};
