import { Button } from 'bv-components';
import { t } from 'bv-i18n';

const SeeAllSettledBets = () => (
  <div className="co-history-link">
    <p className="co-history-link__text">{t('javascript.cashout.settled.see_all.message')}</p>
    <Button
      inverse
      secondary
      className="co-history-link__button"
      to="/bv_cashier/history/4"
      label={t('javascript.cashout.settled.see_all.button')}
    />
  </div>
);

export default SeeAllSettledBets;
