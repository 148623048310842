import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectSoFarScores } from 'Cashout/state/selectors';
import ProgressBar from './progress_bar';

const SoFarScores = ({ id }) => {
  const scores = useSelector(selectSoFarScores);
  const score = scores[id];

  if (!score) return null;

  const {
    scoreInfo, scoreCurrent, scoreTarget, scoreResult,
  } = score;

  return (
    <div className="so-far-scores">
      <ProgressBar
        scoreCurrent={scoreCurrent}
        scoreTarget={scoreTarget}
        scoreResult={scoreResult}
      />
      <span className="so-far-scores__details">
        {scoreInfo}
      </span>
    </div>
  );
};

SoFarScores.propTypes = {
  id: PropTypes.string,
};

SoFarScores.defaultProps = {
  id: null,
};

export default SoFarScores;
