import PropTypes from 'prop-types';

const Clock = ({ clk }) => (
  <p>{clk}</p>
);

Clock.propTypes = {
  clk: PropTypes.string.isRequired,
};

export default Clock;
