import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Bet from 'Cashout/components/bet/bet_redux';
import CashoutInfo from './cashout_info_container';
import EmptyMessage from './empty_message';
import SeeAllSettledBets from './see_all_settled_bets';
import Spinner from './spinner';

const BetsView = ({
  betsIds, betsStatus, isEventLevel, loading, nextPage,
}) => (
  <>
    {
      (!loading || nextPage > 1) && (
        betsIds.length ? (
          <ul className="co">
            {
              betsIds.map((id, index) => (
                <Fragment key={id}>
                  <Bet id={id} />
                  {!isEventLevel && betsStatus === 'open' && index === 0 && <CashoutInfo />}
                </Fragment>
              ))
            }
          </ul>
        ) : <EmptyMessage betsStatus={betsStatus} />
      )
    }
    {loading && <Spinner />}
    {!isEventLevel && betsStatus === 'settled' && <SeeAllSettledBets />}
  </>
);

BetsView.propTypes = {
  betsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  betsStatus: PropTypes.oneOf(['event', 'open', 'settled']).isRequired,
  isEventLevel: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  nextPage: PropTypes.number,
};

BetsView.defaultProps = {
  nextPage: null,
};

export default BetsView;
