import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const messages = {
  event: [],
  open: ['cashout_no_bets', 'cashout_no_bets_track', 'cashout_no_bets_check'],
  settled: ['settled.empty_bets.title', 'settled.empty_bets.body'],
};

const EmptyMessage = ({ betsStatus }) => (
  <div className="co-empty">
    {
      messages[betsStatus].map((key) => <p key={key}>{t(`javascript.cashout.${key}`)}</p>)
    }
  </div>
);

EmptyMessage.propTypes = {
  betsStatus: PropTypes.oneOf(['event', 'open', 'settled']).isRequired,
};

export default EmptyMessage;
