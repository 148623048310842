import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { Reversible } from 'bv-components';

const Default = ({ scoreboard: { americanFormat, scrA, scrB } }) => (
  <div>
    <Reversible reverse={americanFormat && bvVar('useAmericanFormat')}>
      <p>{scrA}</p>
      <p>{scrB}</p>
    </Reversible>
  </div>
);

Default.propTypes = {
  scoreboard: PropTypes.shape({
    americanFormat: PropTypes.bool.isRequired,
    scrA: PropTypes.number.isRequired,
    scrB: PropTypes.number.isRequired,
  }).isRequired,
};

export default Default;
