import { isNone } from './legs';

const sameState = (legA, legB) => isNone(legA.values) === isNone(legB.values);

const compareState = (legA, legB) => ((isNone(legA.values) && !isNone(legB.values)) ? -1 : 1);

const sameStartTime = (legA, legB) => legA.event.startTime === legB.event.startTime;

const compareStartTime = (legA, legB) => ((legA.event.startTime < legB.event.startTime) ? -1 : 1);

export const compareLegs = (legA, legB) => {
  // 1st precedence: not resulted legs
  if (!sameState(legA, legB)) {
    return compareState(legA, legB);
  }
  // 2nd precedence: leg start time
  if (!sameStartTime(legA, legB)) {
    return compareStartTime(legA, legB);
  }
  return 0;
};
