import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Leg from 'Cashout/components/leg/leg_redux';
import { compareLegs } from 'Cashout/helpers/compare_legs';

const LegsView = ({ betSource, isMultiple, legs }) => {
  const sortedLegs = useMemo(() => legs.sort(compareLegs), [legs]);

  return (
    <ul className="co-legs">
      {
        sortedLegs.map((leg) => (
          <Leg
            leg={leg}
            betSource={betSource}
            isMultiple={isMultiple}
            key={leg.event.id}
          />
        ))
      }
    </ul>
  );
};

LegsView.propTypes = {
  betSource: PropTypes.string,
  isMultiple: PropTypes.bool.isRequired,
  legs: PropTypes.arrayOf(Object).isRequired,
};

LegsView.defaultProps = {
  betSource: null,
};

export default LegsView;
