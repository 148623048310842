import _ from 'underscore';
import { toCurrency } from 'bv-helpers/number';

const FLOOR = 1;
const NON_TRIVIAL_BET_THRESHOLD = 3;
const NON_TRIVIAL_BET_STEPS = 200;
const TRIVIAL_BET_STEP = 0.01;

const keepDecimals = (keep, val) => {
  const scale = 10 ** keep;
  const float = parseFloat(val);
  return Math.round(float * scale) / scale;
};

const keepTwoDecimals = _.partial(keepDecimals, 2);

const greaterThan = (reference, number) => number > reference;

const greaterThanFloor = _.compose(_.partial(greaterThan, FLOOR), parseFloat);

const toFixedWith = (fractionDigits, num) => num.toFixed(fractionDigits);

const keepTwoDecimalsAsString = _.compose(_.partial(
  toFixedWith,
  2,
), keepTwoDecimals);

export const isOffered = _.compose(_.partial(_.every, _, greaterThanFloor), Array);

export const keepTwoDecimalsAndCurrency = _.compose(toCurrency, keepTwoDecimalsAsString);

export const getRange = (fullCashout) => {
  const fco = keepTwoDecimals(fullCashout);

  const step = fco > NON_TRIVIAL_BET_THRESHOLD
    ? keepTwoDecimals(
      (fco - FLOOR) / NON_TRIVIAL_BET_STEPS,
    )
    : TRIVIAL_BET_STEP;

  const numberOfSteps = Math.floor((fco - FLOOR) / step);

  const min = keepTwoDecimals(
    Math.max(fco - step * numberOfSteps, FLOOR),
  );

  return {
    min,
    max: fco,
    step: 0.01,
  };
};

export const proportionPartialCashout = (oldPartial, oldFull, newFull) => {
  if (oldPartial && oldFull && newFull) {
    const proportioned = (
      (parseFloat(oldPartial) - FLOOR)
        / (parseFloat(oldFull) - FLOOR))
        * (parseFloat(newFull) - FLOOR) + 1;

    return Math.min(keepTwoDecimals(proportioned), newFull);
  }
  return undefined;
};

export const bankMyStakePosition = (stake, cashoutValue) => ((stake - FLOOR)
  / (cashoutValue - FLOOR)) * 100;
