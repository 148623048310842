import PropTypes from 'prop-types';
import withLazyLoad from 'bv-lazy-load';
import ReAddToBetslipButton from './re_add_to_betslip_button';

const AddToBetslip = withLazyLoad()('luckyDip.addToBetslip');

const ReAddToLuckyDipBetslip = ({
  betSource,
  betSourceId: luckyDipId,
  isSettledBet,
}) => {
  if (isSettledBet || !luckyDipId) {
    return null;
  }

  return (
    <AddToBetslip
      betSource={betSource}
      buttonComponent={ReAddToBetslipButton}
      luckyDipId={luckyDipId}
    />
  );
};

ReAddToLuckyDipBetslip.propTypes = {
  betSource: PropTypes.string.isRequired,
  betSourceId: PropTypes.string,
  isSettledBet: PropTypes.bool.isRequired,
};

ReAddToLuckyDipBetslip.defaultProps = {
  betSourceId: null,
};

export default ReAddToLuckyDipBetslip;
