import Default from './default';
import Sets from './sets';

export default ({
  scrA, scrB, scoresA, scoresB,
}) => {
  if (scoresA && scoresB) {
    return Sets;
  }
  if ((scrA || scrA === 0) && (scrB || scrB === 0)) {
    return Default;
  }

  return null;
};
