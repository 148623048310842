import { t } from 'bv-i18n';

export const getErrorMessage = (code, failureCode) => {
  if (code === 1) {
    switch (failureCode) {
      case 7:
        return t('javascript.cashout.error_codes.cashed_out');
      case 5:
        return t('javascript.cashout.error_codes.settled');
      case 8:
        return t('javascript.cashout.error_codes.voided');
      default:
        return t('javascript.cashout.error_codes.generic');
    }
  } else if (code === -1000 && failureCode === undefined) {
    return t('javascript.cashout.error_codes.login');
  }
  return t('javascript.cashout.error_codes.generic');
};
