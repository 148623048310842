import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import SuspendedMessage from './suspended_message';

const SuspendedIconView = ({ suspensions }) => {
  const showInfo = () => {
    window.ModalHelper.showInfoMessage({
      title: t('javascript.cashout.suspensions.title'),
      children: <SuspendedMessage suspensions={suspensions} />,
      iconClosable: false,
      actions: [{
        label: t('javascript.close'),
        secondary: true,
        close: true,
      }],
    });
  };

  return suspensions.length > 0 && (
    <span onClick={showInfo} className="bvs-icon bvs-link is-information co-wrapper__button-info" />
  );
};

SuspendedIconView.propTypes = {
  suspensions: PropTypes.arrayOf(Object).isRequired,
};

export default SuspendedIconView;
