import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { Reversible } from 'bv-components';

const Sets = ({
  scoreboard: {
    headers, americanFormat, scoresA, scoresB,
  },
}) => (
  <>
    {headers.map((h, i) => (
      <div key={h}>
        <p>{h}</p>
        <Reversible reverse={americanFormat && bvVar('useAmericanFormat')}>
          <p>{scoresA[i]}</p>
          <p>{scoresB[i]}</p>
        </Reversible>
      </div>
    ))}
  </>
);

Sets.propTypes = {
  scoreboard: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    americanFormat: PropTypes.bool.isRequired,
    scoresA: PropTypes.arrayOf(PropTypes.number).isRequired,
    scoresB: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

export default Sets;
