import { isInRunning, earliestLeg } from 'Cashout/helpers/bets';
// Acceptance:
// 1. bets that have at least one in-running leg must be displayed first ordered
// by event start time desc
// 2. bets that do not have in-running legs must be displayed after ordered
// by event start time desc
// 3. In case all bets have the same start date they should be ordered
// by the time that the bet is placed.
// 4. In case of a multiple bet use the start time of the earliest leg of the bet

/* In Running criteria, In Running bets will be displayed first than the others */

const sameInRunningState = (betA, betB) => isInRunning(betA) === isInRunning(betB);

const compareInRunningState = (
  betA, betB,
) => ((isInRunning(betA) && !isInRunning(betB)) ? -1 : 1);

// Early leg criteria,
// we will display first the bet which first leg starts earlier than the others
const sameEarliestLegStartTime = (
  betA, betB,
) => earliestLeg(betA).event.startTime === earliestLeg(betB).event.startTime;

const compareEarliestLegStartTime = (
  betA, betB,
) => ((earliestLeg(betA).event.startTime < earliestLeg(betB).event.startTime) ? -1 : 1);

// Bet creation criteria,
// we will display first bets whose creation date is earlier than the others
const sameBetCreateDate = (betA, betB) => betA.createDate === betB.createDate;

const compareBetCreateDate = (
  betA, betB,
) => ((betA.createDate < betB.createDate) ? -1 : 1);

const compareSettledDates = (betA, betB) => new Date(betB.settledDate).getTime()
  - new Date(betA.settledDate).getTime();

export const compareBets = (betA, betB) => {
  if (betA.isSettledBet) {
    return compareSettledDates(betA, betB);
  }
  // 1st precedence: In Running State
  if (!sameInRunningState(betA, betB)) {
    return compareInRunningState(betA, betB);
  }
  // 2nd precedence: Earliest leg start time
  if (!sameEarliestLegStartTime(betA, betB)) {
    return compareEarliestLegStartTime(betA, betB);
  }
  // 3rd precedence: Bet creation date
  if (!sameBetCreateDate(betA, betB)) {
    return compareBetCreateDate(betA, betB);
  }
  return 0;
};
