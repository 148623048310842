import PropTypes from 'prop-types';
import classnames from 'classnames';

const SCORE_RESULTS = {
  LOSE: {
    progressResult: 'is-lost',
  },
  WIN: {
    progressResult: 'is-won',
  },
  PENDING: {
    progressResult: 'is-won',
  },
};

const isNotSet = (value) => [null, undefined].includes(value);

const ProgressBar = ({ scoreCurrent, scoreTarget, scoreResult }) => {
  if (isNotSet(scoreCurrent) || isNotSet(scoreTarget) || isNotSet(scoreResult)) return null;
  const { progressResult } = SCORE_RESULTS[scoreResult.toUpperCase()] || {};

  const progressPercents = Math.min(Math.round((scoreCurrent / scoreTarget) * 100), 100);
  const progressClassName = classnames('so-far-scores__progress', progressResult);

  return (
    <div className={progressClassName}>
      <div className="so-far-scores__progress-result" style={{ width: `${progressPercents}%` }} />
    </div>
  );
};

ProgressBar.propTypes = {
  scoreCurrent: PropTypes.number,
  scoreTarget: PropTypes.number,
  scoreResult: PropTypes.string,
};

ProgressBar.defaultProps = {
  scoreCurrent: null,
  scoreTarget: null,
  scoreResult: null,
};

export default ProgressBar;
